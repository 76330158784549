<template>
  <div v-if="record" class="container-register">
    <h3 class="text-center">{{ "تعديل المتابعة" | translate }}</h3>
    <hr />
    <div class="">
      <h1 class="text-center">{{ "معلومات المعدة" | translate }}</h1>
      <hr />
      <div class="group" dir="rtl">
        <h3 class="heading">
          {{ record.category }}
        </h3>
        <div class="row justify-content-center">
          <div class="col-md-6 p-0">
            <div class="details p-md-4">
              <div class="item">
                <div class="prop">{{ "الموقع" | translate }}:</div>
                <p>{{ record.location }}</p>
              </div>
              <div class="item">
                <div class="prop">{{ "كود المعدة" | translate }}:</div>
                <p>{{ record.code | translate }}</p>
              </div>
              <hr />
            </div>
          </div>
          <div class="col-md-6 center">
            <qr-code
              :id="id"
              :name="`${record._id}-${record.category}-${record.location}-${record.code}.png`"
            ></qr-code>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <!---------------------------->
    <div class="followup">
      <h2 class="text-center">{{ "تقرير المتابعة" | translate }}</h2>
      <div class="row g-3 justify-conent-cent">
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ "الحالة" | translate }}</label>
            <select class="form-control" v-model="record.state">
              <option v-for="state in states" :key="state.code" :value="state.code">
                {{ state.desc }} ({{ state.code }})
              </option>
            </select>
          </div>
        </div>
        <!--------------------------->
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ "الأجراء" | translate }}</label>
            <select class="form-control" v-model="record.procedure">
              <option
                v-for="procedure in procedures"
                :key="procedure.code"
                :value="procedure.code"
              >
                {{ procedure.desc }} ({{ procedure.code }})
              </option>
            </select>
          </div>
        </div>
        <!-------------------->
        <div class="col-md-6">
          <div class="form-group">
            <label>{{ "التاريخ" | translate }}</label>
            <input type="date" class="form-control" v-model="record.date" />
          </div>
        </div>
        <div v-if="catElement != 0" class="col-md-6">
          <div class="form-group">
            <label>{{ catElement }}</label>
            <input type="number" min="0" class="form-control" v-model="record.element" />
          </div>
        </div>
      </div>
      <!------------------->
      <div v-if="loading" class="row justify-content-center">
        <div class="col-md-5">
          <loading />
        </div>
      </div>
      <!------------------->
      <hr />
      <div class="row g-2 justify-content-center">
        <div class="col-md-4 center">
          <button @click="editRecord" class="btn btn-success center">
            {{ "تعديل المتابعة" | translate }}
          </button>
        </div>

        <div class="col-md-4 center">
          <button @click="$router.push('/')" class="btn btn-danger center">
            {{ "فحص الة جديدة" | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  mounted() {},
  created() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id;
      this.loading = true;
      if (!this.online) {
        this.record = this.$store.getters.locationRecords.find(
          (rec) => rec._id == this.id
        );
        this.states = this.getStates();
        this.procedures = this.getProcedures();
        this.loading = false;
        return;
      }
      axios.get("/getRecordById/" + this.id).then((res) => {
        this.loading = false;
        if (res.data.err) return alert("قم بأعادة تحميل الصفحة");
        this.record = res.data.record;
        ///////////////////////
        this.states = this.getStates();
        this.procedures = this.getProcedures();
      });
    }
  },
  components: {},
  data() {
    return {
      id: null,
      record: null,
      loading: false,
      /////////////////////
      states: [],
      procedures: [],
    };
  },
  computed: {
    auth() {
      return this.$store.getters.auth;
    },
    online() {
      return this.$store.getters.online;
    },
    catElement() {
      const deviceCat = this.record.category;
      if (!deviceCat) return "أفة";
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.element;
    },
    stateName() {
      if (this.states.length == 0 || !this.record) return "";
      return this.states.find((state) => state.code == this.record.state).desc;
    },
    procedureName() {
      if (this.procedures.length == 0 || !this.record) return "";
      return this.procedures.find((procedure) => procedure.code == this.record.procedure)
        .desc;
    },
  },
  methods: {
    async editRecord() {
      if (!this.record.state || !this.record.procedure)
        return alert("اكمل البيانات المطلوبة");
      const record = {
        ...this.record,
        stateName: this.stateName,
        procedureName: this.procedureName,
      };
      this.$store.dispatch("editRecord", record);
    },

    getStates() {
      const deviceCat = this.record?.category;
      if (!deviceCat) return [];
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.states;
    },
    getProcedures() {
      const deviceCat = this.record?.category;
      if (!deviceCat) return [];
      const category = this.$store.getters.categories.find(
        (cat) => cat.name == deviceCat
      );
      return category.procedures;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  direction: rtl;
  text-align: right;
}
.container-register {
  padding: 20px;
  // background: rosybrown;
  @media (max-width: 500px) {
    padding: 60px 2px;
  }
}
.form {
  width: 100%;
  h3 {
    color: rgba(85, 85, 85, 0.782);
  }
  label {
    color: #666;
  }
}
.form-group {
  .error-msg {
    color: #dc3545;
    margin-top: 5px;
    &::before {
      content: "\f06a";
      font: normal normal normal 14px/1;
      font-size: 14px;
      font-size: inherit;
      text-rendering: auto;
      -moz-osx-font-smoothing: grayscale;
      margin-left: 5px;
    }
  }
}
.download {
  color: rgb(13, 112, 51);
  // text-decoration: none;
}
</style>
